$font-primary: macho, sans-serif;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-extrabold: 800;

$color-primary: #1d3768;
$color-secondary: #6a6b6a;
$color-dark: #a7bbbf;
$color-light: #f6fbff;
$color-faded: #a7bbbf;
$color-error: rgb(255, 0, 0);

$two-columns: 19.6rem;

$nav-breakpoint: 945px;

$containter-padding: 0 12px;