.page-event {
    width: 994px;
    max-width: 100%;
    line-height: 27px;
    display: grid;
    grid-template-areas:
        'h'
        'i'
        'm'
        'c'
        'a';
    .section__header {
        max-width: 538px;
        grid-area: h;
    }
    &__image {
        grid-area: i;
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 27px;
        img {
            max-width: 100%;
        }
    }
    article {
        grid-area: c;
        line-height: 27px;
        font-weight: $font-medium;
        margin: 1em 0;
        p:first-child {
            margin-top: 0;
        }
    }
    &__meta {
        grid-area: m;
        p:first-child {
            margin-top: 0;
        }
    }
    aside {
        grid-area: a;
    }
    @media screen and (min-width: 600px) {
        grid-template-areas:
            'h h'
            'i i'
            'm c'
            'a c';

        &__meta {
            margin-bottom: 4em;
        }
        &__meta,
        &__image {
            padding-left: 184px;
        }
        &__image {
            margin-top: -84px;
        }
        aside {
            margin-top: auto;
        }
        article {
            margin: 0;
            margin-left: 6em;
        }
    }
}
