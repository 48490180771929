.page-knowledge {
    .publications {
        margin-top: -1em;
    }
    @media screen and (min-width: 920px) {
        .publications {
            margin-top: -2em;
            margin-left: 184px;
            min-height: 511px;
        }
    }
}

.knowledge-list {
    border-top: 10px solid #1d3768;

    display: flex;
    flex-wrap: wrap;
    padding: 10px 12px;

    @media screen and (min-width: 920px) {
        padding: 30px 12px;
    }
    @media screen and (min-width: 1130px) {
        padding: 30px 0;
    }

    aside {
        display: block;
        width: 100%;

        ul {
            display: flex;
            margin: 0 0 2em;
            padding: 0;
            list-style: none;
            li + li {
                margin-left: 10px;
            }
        }

        a {
            display: block;
            color: #6a6b6a;
            font-size: 16px;
            line-height: 27px;
            font-weight: $font-medium;

            &.active {
                position: relative;
                color: #1d3768;
                &:before {
                    position: absolute;
                    top: 120%;
                    left: 50%;
                    transform: translateX(-50%);
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: #1d3768;
                }
            }
            &:hover {
                color: #1d3768;
            }
        }
        @media screen and (min-width: 920px) {
            min-width: 184px;
            width: auto;
            ul {
                display: block;
                li + li {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
            a.active {
                &:before {
                    top: 50%;
                    transform: translateY(-50%);
                    left: -1.4em;
                }
            }
        }
    }
    .list {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;

        @media screen and (min-width: 663px) {
            grid-template-columns: 1fr 1fr;
        }

        .knowledge-item.load-more {
            background: #3d71d5;
            &:before {
                display: none;
            }
            &:after {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: 20%;
                height: 20%;
                content: url('../svg/plus_circled.svg');
                @media screen and (min-width: 431px) {
                    top: 50%;
                    left: 50%;
                    width: 131px;
                    height: 131px;
                }
            }
        }
    }
}

.knowledge-item {
    display: block;
    position: relative;
    max-height: 296px;
    height: 100%;
    a {
        display: block;
        height: 100%;

        position: relative;
        z-index: 3;
        overflow: hidden;
    }
    a:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(35, 36, 35, 0.5);
        z-index: 2;
    }
    img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 1;

        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        transition-duration: 0.3s;
        transition-property: transform;
    }
    span {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 3;
        color: #f8f8f8;
        font-size: 16px;
        font-weight: $font-bold;
        @media screen and (min-width: 431px) {
            font-size: 21px;
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.page-publication {
    width: 100%;
    display: grid;
    grid-template-areas:
        'h'
        'i'
        'm'
        'c'
        'a';

    .section__header {
        max-width: 538px;
        grid-area: h;
    }
    &__image {
        margin: 0;
        display: flex;
        align-items: flex-start; /* new */
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;
        margin-bottom: 27px;
        img {
            max-width: 100%;
        }
    }
    article {
        line-height: 27px;
        font-weight: $font-medium;
        margin: 1em 0;
        p:first-child {
            margin-top: 0;
        }
    }
    &__meta {
        width: 100%;
        padding-left: 0;

        @media screen and (min-width: 990px) {
            width: 260px;
        }
    }
    .section-related-articles {
        grid-area: a;
    }
    &__author {
        font-size: 12px;
        line-height: 24px;

        figure {
            margin: 34px 0;
            img {
                border-radius: 50%;
                width: 92px;
                height: 92px;
                object-fit: cover;
            }
        }
        h4,
        .link-with-arrow {
            color: #3d71d5;
        }
        .link-with-arrow {
            font-weight: $font-bold;
        }
        h4 {
            margin: 0.4em 0 0;
            font-size: 21px;
            font-weight: $font-medium;
        }
        strong {
            font-size: 12px;
            font-weight: $font-bold;
            color: #1d3768;
        }
    }
    &__info {
        grid-area: c;
        display: grid;
        grid-template-areas:
            'i i'
            'm m'
            'c c'
            'a a';
        padding: 0 12px;
        .page-publication__image {
            grid-area: i;
        }
        article {
            grid-area: c;
        }
        .page-publication__meta {
            grid-area: m;
        }
        .page-publication__author {
            grid-area: a;
            grid-row-start: a-start;
        }
        p:first-child {
            margin-top: 0;
        }
    }
    @media screen and (min-width: 600px) {
        grid-template-areas:
            'h h'
            'i i'
            'c c'
            'a a';

        &__image {
            margin-top: -84px;
        }
        article {
            margin: 0;
            max-width: 536px;
        }
    }
    @media screen and (min-width: 990px) {
        &__info {
            width: 904px;
            max-width: 100%;
            margin: 0 auto 84px;
            padding: 0;
            grid-template-columns: 2fr 1fr;
            grid-template-areas:
                'i i'
                'c m'
                'c a';

            .page-publication__author {
                grid-row-start: m-start;
                margin-top: 10em;
            }
        }
    }
}
.section-related-articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    .title {
        flex-basis: 100%;
    }
    .section__links {
        flex-basis: 100%;
    }
    .related-articles {
        width: 100%;
        display: flex;
        flex-basis: 100%;

        .knowledge-item + .knowledge-item {
            margin-left: 16px;
        }
        .knowledge-item {
            flex-basis: 50%;
            img {
                object-fit: cover;
            }
        }
    }
    @media screen and (min-width: 750px) {
        .section__links {
            flex-basis: 25%;
        }
        .related-articles {
            flex-basis: 65%;
        }
    }
}
