.page-our-story {
    display: grid;
    grid-template-areas:
        'i'
        'c'
        'a';
    .section__header {
        position: relative;
        z-index: 2;
        grid-area: i;
    }
    &__image {
        grid-area: i;
        padding: 2em 0 4em 4em;
    }
    article {
        padding: $containter-padding;
        padding-bottom: 1em;
        grid-area: c;
        color: #232423;
        h3 {
            margin: 0 0 20px 0;
            padding: 0;
            font-size: 21px;
            font-weight: $font-medium;
        }
        p {
            font-size: 1em;
        }
        p + h3 {
            margin-top: 40px;
        }
    }
    aside {
        grid-area: a;
        align-self: end;
    }
    @media screen and (min-width: 767px) {
        grid-template-areas:
            'i c'
            'a c';
    }
    @media screen and (min-width: $nav-breakpoint){
        article {
            padding: 0 0 1em;
        }
    }
}
