.contacts {
    display: flex;
    flex-direction: column;
    align-items: center;

    figure {
        max-width: 720px;
        margin: -2em 0 0;
        position: relative;
        z-index: 0;
    }
    &__item {
        max-width: 720px;
        width: 100%;
        text-align: center;
        padding: 2em 0;
        margin: 2em 0;
        a {
            font-size: 38px;
            color: #1d3768;
        }
        a[href^='mailto:'] {
            font-weight: $font-medium;
            color: #3d71d5;
        }

        &--bg {
            background-color: #E2E0E0;
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__col {
        flex-basis: 100%;
        @media screen and (min-width: 510px) {
            flex-basis: 50%;
        }
        text-align: center;
        h5 {
            font-size: 24px;
            font-weight: $font-bold;
        }
        a, p, span {
            font-size: 24px;
            font-weight: $font-medium;
            color: #232423;
        }
        p {
            margin: 0 auto;
        }
    }
    @media screen and (min-width: 800px) {
        &__col + &__col {
            position: relative;
            &:before {
                position: absolute;
                top: 2em;
                left: 0;
                content: '';
                width: 1px;
                height: 90%;
                background-color: #707070;
            }
        }
    }

    .icon {
        display: inline-block;
        width: 38px;
        margin-right: 0.4em;
    }
}
