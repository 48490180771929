.friends-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 0 6em;
}

.friends-list__item {
    display: flex;
    max-width: 700px;
    width: 100%;

    & + & {
        margin-top: 10rem;
    }

    h5 {
        margin: 0;
        font-size: 16px;
    }
}

.friends-item__name {
    flex-basis: 30%;
}
.friends-item__content {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    p {
        margin-top: 0;
    }
}
