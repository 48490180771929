.link-view,
.link-all {
    padding-bottom: 3px;
    line-height: 21px;
    font-size: 1.4rem;
    font-weight: $font-medium;
    color: #3d71d5;
    overflow: visible;
    
    
    &.no-arrow {
        .icon-arrow {
            margin-left: 0.3em;
        }
    }

    @media screen and (min-width: $nav-breakpoint){        
        font-size: 1.6rem;
        line-height: 27px;
    }
}
.icon-arrow {
    &:before {
        content: '\279d';
    }
    display: inline-block;
}

.home-intro-sections {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;

    @media screen and (min-width: 1107px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 160px 0;
    }
    @media screen and (min-width: 1300px) {
        overflow: visible;
    }

    .section--with-line:before {
        top: -5px;
    }
}

.home-company-overview {
    position: relative;
    max-width: 100%;

    font-size: 2.1rem;
    background: #e2e0e0;
    padding: 20px 80px 20px 20px;

    h3 {
        margin: 6px 0;
        padding: 0;
        font-weight: $font-medium;
        color: #1D3768;
    }

    p {
        font-weight: $font-medium;
        font-size: 16px;
        line-height: 21px;
        color: #6a6b6a;
        @media screen and (min-width: $nav-breakpoint) {
            font-size: 21px;
            line-height: 27px;
        }
    }
    @media screen and (min-width: 1107px) {
        width: 628px;
    }
}

.home-news {
    width: 332px;
    max-width: 100%;
    padding: $containter-padding;
    padding-bottom: 2em;
    order: 1;
    font-weight: $font-medium;

    h4 {
        margin: 1em 0;
        font-size: 16px;
        font-weight: $font-medium;
        color: $color-primary;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }
    @media screen and (min-width: 1107px) {
        grid-row-start: 2;
    }

    @media screen and (min-width: 1107px) {
        order: initial;
        padding-bottom: 4em;
    }
}

.core-expertises {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 0;

    &__item {
        flex-basis: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
        margin: 1em;

        &:hover {
            svg {
                transform: scale(1.1);
            }
        }
    }
    svg {
        display: inline-block;
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        transition-duration: 0.3s;
        transition-property: transform;
        max-height: 120px;
    }

    h5 {
        margin: 16px 0;
        font-weight: $font-bold;
        font-size: 21px;
    }

    @media screen and (min-width: 853px) {
        padding: 90px 0;
        justify-content: space-between;
        .core-expertises__item {
            flex-basis: auto;
            max-width: 182px;
        }
    }
    @media screen and (min-width: 1680px) {
        .core-expertises__item {
            margin-left: 0;
        }
    }
}

.home-team {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;

    h3 {
        flex-basis: 100%;
    }
    figure {
        position: absolute;
        top: 0;
        margin: 0;
        z-index: 0;
        width: 100%;
        height: 190px;

        img {
            max-height: 190px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
        @media screen and (min-width: 870px) {
            left: -140px;
            display: block;
            width: 100%;
            height: auto;
            img {
                max-height: 350px;
            }
        }
    }
    &:before {
        top: -5px;
        z-index: 1;
    }
    .title {
        z-index: 2;
    }
    &__info,
    &__join {
        @media screen and (min-width: 870px) {
            flex-basis: 50%;
        }
    }
    &__info {
        padding: $containter-padding;
    }
    @media screen and (min-width: $nav-breakpoint){
        margin-bottom: 120px;
        &__info {
            padding: 0;
        }
    }
}

.home-team__info {
    margin-top: 150px;
    margin-bottom: 2em;
    max-width: 608px;
    font-size: 16px;
    font-weight: $font-medium;
    line-height: 21px;

    ul {
        list-style: none;
        padding: 0;
        font-size: 21px;
        line-height: 27px;

        li {
            margin-bottom: 1em;
        }
    }
    a {
        color: #3d71d5;
    }
    @media screen and (min-width: 870px) {
        margin-top: 320px;
        font-size: 21px;
        line-height: 27px;
    }
}

.home-team__join {
    max-width: 382px;

    background: #e2e0e0;
    padding: 27px 20px;

    strong {
        font-size: 21px;
        line-height: 27px;
    }
    p {
        font-size: 16px;
        font-weight: $font-medium;
        line-height: 24px;
    }
    @media screen and (min-width: 1205px) {
        margin-top: 230px;
        margin-right: -30px;
    }
}

.clients-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    padding: 46px 0;

    color: #fff;

    &__item {
        width: 100%;
        max-width: 260px;
        height: 208px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 18px;
        line-height: 24px;
        font-weight: $font-bold;
        text-align: center;

        .icon {
            max-width: 82px;
            margin-bottom: 16px;
        }

        &--color-first {
            background: #6a6b6a;
        }
        &--color-second {
            background: #a9afbd;
        }
        &--color-third {
            background: #3d71d5;
        }
        &--color-fourth {
            background: #1d3768;
        }
        &--color-five {
            background: #232423;
        }
        @media screen and (min-width: 500px) {
            font-size: 21px;
            line-height: 27px;
        }
    }

    @media screen and (min-width: 747px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 1155px) {
        grid-template-columns: repeat(4, 1fr);
    }
}
