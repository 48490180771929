.team-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 12px 2em;

    @media screen and (min-width: $nav-breakpoint){
        padding: 4em 0 6em;
    }
}

.team-list__item {
    display: flex;
    max-width: 700px;

    &:nth-child(even) {
        flex-direction: row-reverse;
        .team-item__content {
            padding-right: 1em;
            padding-left: 0;
        }
    }
    figure {
        flex-basis: 40%;
        margin: 0;
    }
    & + & {
        margin-top: 10rem;
    }
}

.team-item__content {
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    h5 {
        margin: 0;
        font-size: 21px;
    }
}

.team-page-top {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -1em;
    width: 100%;
    padding: $containter-padding;

    .icon {
        display: inline-block;
        width: 32px;
    }

    figure {
        order: 1;
        margin: 0;
        flex-basis: 80%;
        padding: 0 2em 2em 0;
        text-align: right;
    }

    .content {
        order: 3;
        flex-basis: 100%;
        p:first-child {
            margin-top: 0;
        }
    }

    .social-links {
        order: 2;
        flex-basis: 32px;
        display: flex;
        flex-direction: column;
        a {
            display: block;
        }
        a + a {
            margin-top: 1em;
        }
    }
    @media screen and (min-width: 783px) {
        padding: 0;
        margin-top: -3em;
        figure {
            order: 1;
            flex-basis: 55%;
        }
        .content {
            order: 2;
            flex-basis: 35%;
        }
        .social-links {
            order: 3;
        }
    }
}
.page-team-member {
    width: 100%;
    display: grid;
    grid-template-areas:
        'h'
        'ct'
        'as'
        'c'
        'a';

    aside {
        grid-area: a;
        margin-top: 1em;
        &.top {
            grid-area: as;
        }
    }
    aside + aside {
        margin-top: auto;
        align-self: end;
    }
    .section__header {
        .title {
            font-size: 22px;
            padding: 0.4em 20px;
        }
    }
    .team-page-top {
        grid-area: ct;

        .content {
            font-size: 21px;
            line-height: 30px;
        }
    }
    .team-page-info {
        grid-area: c;
        line-height: 27px;
        padding: $containter-padding;
        padding-right: 4em;
        p:first-child {
            margin-top: 0;
        }
    }
    .team-page-info,
    .team-page-top {
        width: 100%;
    }
    @media screen and (min-width: 767px) {
        grid-template-columns: 1fr 2fr;
        grid-template-areas:
            'h h'
            'ct ct'
            'as c'
            'a c';
        .section__header {
            .title {
                font-size: 38px;
                line-height: 46px;
            }
        }
        .team-page-info {
            padding-left: 0;
        }
        aside {
            max-width: 260px;
        }
    }
}
