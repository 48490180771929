.lists {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 2em 0 3em 0;

    &__item {
        padding-right: 2em;
    }

    h3 {
        font-size: 16px;
        a {
            color: #3d71d5;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        font-size: 15px;
        color: #a9afbd;

        li {
            position: relative;
            padding: 0.6em 0 0 15px;
            line-height: 18px;

            &:before {
                position: absolute;
                top: 16px;
                left: 0;
                display: inline-block;
                vertical-align: baseline;
                content: '';
                margin-right: 12px;
                width: 8px;
                height: 8px;
                background: #3d71d5;
            }
        }
    }
}

.page-service {
    position: relative;
    &__content {
        max-width: 720px;
        margin: -4em auto 98px;
        line-height: 27px;
        padding: $containter-padding;
        @media screen and (min-width: $nav-breakpoint) {
            padding: 0;
        }
        figure {
            margin: 0 0 3em;
        }
    }
    &__icon {
        float: right;
        width: 138px;
        max-width: 100%;
        margin: 2em 0 2em 2em;
        @media screen and (min-width: 800px) {
            margin: 0;
            position: absolute;
            top: 4em;
            right: 0;
        }
    }
    .section__header h3 {
        max-width: 600px;
    }
    &--secondary {
        display: grid;

        grid-template-areas:
            'h'
            'i'
            'c'
            'a'
            'co'
            'r';

        .section__header {
            grid-area: h;
        }
        .page-service__content {
            grid-area: c;
            margin-top: 0;
        }
        .section-knowledge {
            grid-area: a;
        }
        .section-knowledge {
            grid-area: a;
        }
        .section-contacts {
            grid-area: co;
        }
        .section-related {
            grid-area: r;
        }
        .page-service__image {
            grid-area: i;

            margin: 0;
            width: 100%;
            text-align: center;
        }

        @media screen and (min-width: 900px) {
            grid-template-areas:
                'h h h'
                'i i i'
                'a c co'
                'r c co';
            gap: 108px;

            .page-service__image {
                margin: -150px 0 -4em 0;
            }
            .section-knowledge,
            .section-knowledge,
            .section-contacts,
            .section-related {
                width: 260px;
                max-width: 100%;
            }
        }
    }
}

.section__contacts {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 50px 12px;
    @media screen and (min-width: $nav-breakpoint) {
        padding: 50px 0;
    }
    li {
        min-width: 50%;
        margin-bottom: 2em;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 920px) {
            min-width: 25%;
        }
    }

    img {
        border-radius: 50%;
        width: 92px;
        height: 92px;
        object-fit: cover;
        object-position: top;
    }
    strong {
        margin: 1em 0;
        font-size: 21px;
        font-weight: $font-medium;
    }
    .link-with-arrow {
        color: #3d71d5;
    }
}
