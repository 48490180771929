a {
    color: inherit;
    text-decoration: none;
}

img, iframe, embed {
    max-width: 100%;
}

svg {
    color: inherit;
    fill: currentColor;
}

.logo {
    color: $color-primary;
    .with-hero & {
        color: #fff;
    }
}

p a {
    color: #3d71d5;
}