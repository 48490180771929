html {
    min-height: 100vh;
    font-size: 10px;
    font-family: $font-primary;
    line-height: 1.4;
    letter-spacing: -0.025em;
    box-sizing: border-box;

    scroll-behavior: smooth;
    overflow-x: hidden;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
.background-image {
    position: fixed;
    left: 0;
    top: 0;
    transition: 0s linear;
    transition-property: top;
    width: 100%;
    z-index: -1;
    & > svg {
        max-height: none;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    font-size: 1.6rem;
    background: #f8f8f8;

    padding: 5px 0;

    @media screen and (min-width: $nav-breakpoint) {
        padding: 3.6rem 0 2.5rem;
    }
}

html.has-nav.has-dropdown,
html.has-nav {
    height: 100%;
    overflow-y: hidden;
}
@media screen and (min-width: $nav-breakpoint) {
    html.has-dropdown {
        height: 100%;
        overflow-y: hidden;
    }
}

p {
    word-break: break-word;
}

.container {
    width: 100%;
    max-width: 1112px;
    margin: 0 auto;
    @media screen and (min-width: $nav-breakpoint){ 
        padding: $containter-padding;
    }
}

main {
    padding-top: 50px;
    @media screen and (min-width: $nav-breakpoint) {
        padding-top: 10rem;
    }
}

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 12;

    color: $color-primary;

    .logo {
        display: inline-block;
        max-width: 40px;
        width: 100%;

        content: url('../svg/logo.svg');
        height: 40px;

        &.active {
            max-width: 280px;
            content: url('../svg/logo_full.svg');
            height: 60px;
            margin-right: auto;
        }

        @media screen and (min-width: 930px) {
            max-width: 366px;
            content: url('../svg/logo_full.svg');
            height: 83px;
        }
    }
    .top-links {
        display: none;
        align-items: center;

        position: absolute;
        top: 90vh;
        right: 0;
        z-index: 12;

        font-size: 12px;
        text-transform: uppercase;
        color: inherit;

        list-style: none;

        li + li {
            margin-left: 2em;
        }

        a {
            color: #3D71D5;
        }

        .switch-language {
            color: #1D3768;
        }
        .icon {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            max-width: 16px;
            margin-right: 0.6em;
        }

        @media screen and (min-width: $nav-breakpoint) {
            display: flex;
            bottom: auto;
            top: 0;
            right: 0;
        }
    }
    .main-navigation {
        display: none;
        padding: 0;

        font-weight: $font-bold;
        font-size: 16px;

        ul.navigation {
            list-style: none;
            padding: 0;
            margin: 0;

            display: flex;

            li + li {
                margin-left: 2.2rem;
            }

            .mega-menu {
                li + li {
                    margin-left: 0;
                }
            }

            .dropdown {
                position: relative;
                &:before {
                    display: none;
                    top: 60%;
                    left: 50%;
                    border: solid transparent;
                    content: '';
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(61, 113, 213, 0);
                    border-bottom-color: #3d71d5;
                    border-width: 15px;
                    margin-left: -15px;
                }

                &.active:before {
                    display: block;
                }
                @media screen and (max-width: $nav-breakpoint) {
                    &:after {
                        content: url(../svg/arrow_down.svg);
                        width: 12px;
                        position: absolute;
                        top: -4px;
                        left: -18px;
                    }
                    &.active {
                        &:before {
                            display: none;
                        }
                        &:after {
                            transform-origin: center center;
                            transform: rotate(180deg) translateY(-30%);
                        }
                    }
                }
            }
            & > li > a {
                display: block;
                padding: 0;
                color: inherit;
                line-height: 2em;

                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                position: relative;

                .border {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    right: 100%;
                    bottom: 0;
                    height: 4px;
                    -webkit-transition-property: right;
                    transition-property: right;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                    background: currentColor;
                }
            }
            & > li:hover {
                .border {
                    right: 0;
                }
            } 
        }

        @media screen and (max-width: $nav-breakpoint) {
            position: absolute;
            top: 100%;
            left: -12px;
            right: 0;
            height: calc(100vh - 40px);
            overflow-y: scroll;

            padding: 2em 27px;
            background: #f8f8f8;

            z-index: 10;

            ul.navigation {
                flex-direction: column;
                padding-bottom: 2em;
                font-size: 22px;

                li + li {
                    margin-left: 0;
                    margin-top: 1em;
                }
            }

            &.active {
                display: block;
                height: calc(100vh - 60px);
            }
        }

        @media screen and (min-width: $nav-breakpoint) {
            display: block;
        }
    }
    .mega-menu {
        display: none;
        flex-direction: column;

        @media screen and (max-width: $nav-breakpoint) {
            li {
                padding: 0.2em 1em;
            }
            a {
                font-size: 16px;
                line-height: 17px;
                color: #3d71d5;
            }
        }
        @media screen and (min-width: $nav-breakpoint) {
            position: absolute;
            left: 0;
            top: 120%;
            width: 100%;

            &:before {
                content: '';
                background: #3d71d5;
                width: 100vw;
                height: 100%;
            }
        }
    }
    .dropdown.active {
        & + .mega-menu {
            display: flex;
        }
    }
    .nav-toggle {
        height: 40px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;

        .icon {
            display: flex;
            width: 40px;
            height: 40px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .line {
            fill: none;
            stroke: #1d3768;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
        }
        .line1 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
        }
        .line2 {
            stroke-dasharray: 60 60;
            stroke-width: 6;
        }
        .line3 {
            stroke-dasharray: 60 207;
            stroke-width: 6;
        }

        @media screen and (min-width: $nav-breakpoint) {
            display: none;
        }
    }
    padding: $containter-padding;
    @media screen and (min-width: $nav-breakpoint) {
        padding: 0;
        align-items: flex-end;
    }
}

.has-nav .switch-language {
    display: flex;
}

.nav-toggle.active {
    .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }
    .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
    }
    .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }
}

.mega-menu__close {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    a {
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #f8f8f8;
        font-size: 12px;
        font-weight: $font-medium;
        text-transform: uppercase;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;

            content: 'x';
            width: 36px;
            height: 36px;

            background-color: #3d71d5;
            font-size: 14px;
            font-weight: $font-medium;

            margin-right: 1em;
        }
    }
    @media screen and (min-width: $nav-breakpoint) {
        display: block;
    }
}

.mega-menu__horizontal {
    position: relative;
    display: block;
    strong {
        display: none;
    }
    @media screen and (min-width: $nav-breakpoint) {
        min-height: 98px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        z-index: 6;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: #3d71d5;
            left: -50vw;
            width: 150vw;
            height: 100%;
        }
        li,
        strong {
            position: relative;
            z-index: 2;
        }
        ul {
            display: flex;
            list-style: none;
            justify-content: space-between;
            padding: 0;
            margin: 0.6em 0;
        }
        strong {
            display: block;
            color: #a9afbd;
            font-size: 12px;
            font-weight: $font-medium;
        }
        li {
            a {
                color: #f8f8f8;
                font-size: 20px;
                font-weight: $font-bold;
            }
        }
        li + li {
            margin-left: 1em;
        }
    }
}

.mega-menu__tabs {
    .tabs-controls {
        display: none;
    }
    .tab-title {
        position: relative;
        padding: 0.2em 1em;
        a {
            color: #1d3768;
        }
        &:after {
            content: url(../svg/arrow_down.svg);
            width: 8px;
            position: absolute;
            top: 2px;
            left: 0;
        }
        &.active {
            &:after {
                transform-origin: center center;
                transform: rotate(180deg) translateY(-50%);
            }
        }
    }
    @media screen and (max-width: $nav-breakpoint) {
        .lists__item {
            ul {
                display: none;
            }
            h3 {
                a {
                    font-size: 14px;
                    font-weight: $font-medium;
                    color: #1d3768;
                }
            }
        }
        .lists {
            display: none;
            padding: 0;
            margin-left: 1.6em;
        }
        .tab-title.active + .lists {
            display: block;
        }
    }
    @media screen and (min-width: $nav-breakpoint) {
        display: flex;
        position: relative;
        z-index: 6;
        height: 100vh;
        max-height: calc(100vh - 234px);

        .tabs-controls {
            display: block;
        }
        .tab-title {
            display: none;
        }
        .tabs-controls,
        .lists {
            position: relative;
            z-index: 3;
            padding-top: 50px;
        }

        .tabs-controls:before {
            background: #1d3768;
            content: '';
            position: absolute;
            top: 0;
            right: 40px;
            bottom: 0;
            height: 100%;
            width: 100vw;
        }

        .tabs {
            position: relative;
            max-width: 70%;
            padding-bottom: 2em;

            &:before {
                content: '';
                background: rgba(41, 63, 105, 1);
                position: absolute;
                top: 0;
                left: -40px;
                bottom: 0;
                right: -100vw;
                height: 100%;
            }
        }
        .tab {
            display: none;
            max-height: 100%;

            &.active {
                display: flex;
            }
        }

        .lists {
            grid-template-columns: repeat(3, 1fr);
            gap: 0;
            padding-left: 2em;
            padding-bottom: 2em;
            overflow-y: scroll;

            h3 a {
                color: #f8f8f8;
            }
            ul {
                li,
                li a {
                    color: #a9afbd;
                }
            }
        }
    }
}
.tabs-controls {
    width: 34%;

    ul {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        padding: 0;
        margin: 0;
    }

    .tab-control {
        position: relative;
        display: block;
        padding: 20px;
        max-width: 90%;

        font-size: 20px;
        font-weight: $font-bold;
        color: #f8f8f8;

        &.active {
            background: #e2dfde;
            color: #3d71d5;
            &:after,
            &:before {
                left: 100%;
                top: 0;
                height: 100%;
                border: solid transparent;
                content: '';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:before {
                border-color: rgba(194, 225, 245, 0);
                border-left-color: #e2dfde;
                border-width: 34px;
                // margin-top: -76px;
            }
        }
    }
}
.main-footer {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    padding-top: 1rem;
    padding-right: 3em;

    font-size: 1.2rem;
    font-weight: $font-medium;
    color: $color-secondary;

    nav,
    .copyright {
        flex-basis: 100%;
        text-align: center;
    }
    .logo {
        max-width: 5rem;
        margin: 2em 0;
    }
    a + a {
        margin-left: 1.6rem;
    }
    @media screen and (min-width: 1170px) {
        padding-right: 0;
        padding-top: 13rem;
    }
    @media screen and (min-width: 590px) {
        justify-content: space-between;
        nav,
        .copyright {
            flex-basis: 40%;
        }
        nav {
            text-align: left;
        }
        .copyright {
            text-align: right;
        }
        .logo {
            margin: 0;
        }
    }
}

.design-by {
    position: absolute;
    bottom: 1.6em;
    right: 0;

    transform: rotate(-90deg);
    color: #e2e0e0;

    @media screen and (min-width: 1170px) {
        right: -4em;
    }
}

svg {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.section {
    padding: 32px 0;

    h3.title {
        margin: 0;
        padding: 0 20px;
        font-weight: $font-medium;
        font-size: 22px;
        line-height: 30px;
        color: #1d3768;
        
        strong {
            font-weight: $font-extrabold;
        }
    }

    h5.title {
        margin: 0;
        padding: 0 20px;
        font-size: 21px;
        font-weight: bold;
        color: #232423;
    }

    &--inverted {
        h3.title {
            color: #e2e0e0;
        }
    }
    &--primary {
        h5.title {
            color: #1d3768;
        }
    }

    .lead {
        font-size: 21px;
    }
    @media screen and (min-width: $nav-breakpoint) {
        h3.title {
            font-size: 38px;
            line-height: 46px;
            padding: 0; 
        }
        h5.title {
            padding: 0;
        }
    }
}

.section--with-line {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 260px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        background: $color-primary;
        z-index: 10;
    }

    &.section--inverted {
        &:before {
            background: #e2e0e0;
        }
    }
}

.section--colored-head {
    .section__header {
        position: relative;
        z-index: 5;
        margin-top: -28px;
        h3 {
            display: inline-block;
            margin: 0;
            padding: 1em;
            background: rgba(226, 224, 224, 0.9);
        }
        @media screen and (min-width: 500px) {
            h3 {
                padding: 32px 3em 32px 1em;
            }
        }
    }
}
.section__links {
    width: 100%;
    font-size: 21px;
    font-weight: $font-bold;
    padding: 1em 12px 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
        flex-basis: 50%;
        margin-bottom: 2em;
    }
    a {
        color: #3d71d5;
        padding-right: 1em;
        padding-bottom: 0.5em;
    }
    &--tight {
        font-size: 16px;
    }
    @media screen and (min-width: 767px) {
        padding: 1em 0 0;
        li {
            flex-basis: 100%;
            margin-bottom: 0;
        }
        li + li {
            margin-top: 2em;
        }
        &--tight {
            li + li {
                margin-top: 0.8em;
            }
        }
        &--spaced {
            li + li {
                margin-top: 2em;
            }
        }
    }
}

.main-header .link-with-arrow {
    &:after {
        margin-left: 0;
    }
}

.breadcrumb {
    display: block;
    margin-bottom: 1em;
    padding: $containter-padding;

    font-size: 16px;
    line-height: 18px;

    a {
        color: #3d71d5;
    }
    @media screen and (min-width: $nav-breakpoint) {
        padding: 0;
    }
}

.publications {
    position: relative;
    z-index: 2;

    max-width: 100%;
    padding: 1em 1em 0;
    overflow: hidden;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    min-height: 16em;

    h4 {
        margin: 0.4em 0;
        font-size: 16px;
        font-weight: $font-medium;
        color: #fff;
    }

    h2 {
        font-size: 16px;
        line-height: 21px;
        font-weight: $font-medium;
        color: #fff;
        max-width: 60%;
        @media screen and (min-width: $nav-breakpoint){
            font-size: 38px;
            line-height: 46px;
        }
    }

    figure {
        margin: 0;
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        z-index: -1;
        padding: 0;
        height: 100%;

        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 100%;
            max-height: 600px;
        }
        &:before {
            position: absolute;
            content: "";
            width: 850px;
            min-width: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(35,36,35,.5);
            z-index: 2;
        }
    }
    .link-view {
        color: #3d71d5;
    }
    .link-all {
        float: right;
        color: #fff;
    }

    &__content {
        margin-top: auto;
        padding-bottom: 1em;
        padding-left: 2em;
    }
    &--home {
        h2 {
            max-width: 100%;
        }
        .publications__content {
            padding-left: 0;
        }
    }

    @media screen and (min-width: 1100px) {
        &--home {
            margin: -60% 0 0 -66px;
            width: 592px;
            grid-row-start: 2;
            figure {
                left: -35px;
                img {
                    width: 850px;
                    max-width: 850px;
                    height: 100%;
                }
            }
        }
        padding: 0;
        overflow: visible;
    }
}

@media screen and (max-width: $nav-breakpoint) {
    .main-navigation ul.navigation .top-menu {
        padding: 0;
        list-style: none;
        li + li {
            margin-top: 0;
        }
    }
}

.hvr-underline-from-left::before {
    background-color: currentColor;
}
